* {
  box-sizing: border-box;
}

:active,
:hover,
:focus {
  outline: 0;
  outline-offset: 0;
}

input {
  &::-ms-clear {
    display: none;
  }
}

body {
  font-family: "Montserrat", Arial, sans-serif;
  background: #f6f6f8;
  font-weight: normal;
  line-height: normal;
  min-width: 1230px;
}

.container {
  max-width: 1400px;
  min-width: 1230px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
}

.logo {
  display: flex;
  text-decoration: none;
  transition: 0.3s;
  align-items: center;

  /*&__text {
    font-size: 15px;
    font-weight: 700;
    color: #000;
  }*/

  &__icon {
    height: 50px;
    width: 201px;
    background: url("/img/logo.svg") no-repeat left center;

    /*& + .logo__text {
      margin-left: 10px;
    }*/
  }

  &:hover {
    opacity: 0.6;
  }
}

.v--modal-overlay .v--modal-box {
  overflow: visible !important;
}

[data-modal="dialog"] {
  .vue-dialog {
    border-radius: 12px;
    text-align: center;
    .dialog-content {
      padding: 45px 25px;
    }
    .dialog-c {
      &-title {
        font-size: 25px;
        font-weight: 700;
        color: #333;
      }
      &-text {
        font-size: 16px;
        line-height: 28px;
        font-weight: normal;
        text-align: center;
        color: #333;
      }
    }
    .vue-dialog-buttons {
      .vue-dialog-button {
        height: 69px;
        font-weight: 700;
        font-size: 16px !important;
        text-transform: uppercase;
        color: #7a6afa;
      }
    }
  }
}

.notify-all {
  font-size: 12px;
}
