<template>
  <div class="form-wizard-content--progress">
    <span v-if="message" class="form-wizard-content--progress-title">{{message}}</span>
    <div class="preloader">
      <div class="spinner -active-">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
@Component
export default class Loader extends Vue {
  @Prop({ type: Boolean, default: false }) public isLoading!: boolean;
  @Prop({ type: String }) public message!: string;
}
</script>

<style leng="scss">
.preloader .spinner {
  opacity: 0;
  transition: opacity 0.3s;
  margin: 20px 0 0 0;
}

.preloader .spinner > div {
  width: 18px;
  height: 18px;
  background-color: #7767fa;
  border-radius: 100%;
  display: inline-block;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.preloader .spinner .bounce1 {
  animation-delay: -0.32s;
}

.preloader .spinner .bounce2 {
  animation-delay: -0.16s;
}

.preloader .spinner.-active- {
  opacity: 1;
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}
</style>
